export default {
  hosts: {
    sdk_host: 'https://sdk.shortlyst.com/',
    brandstore_host: 'https://brands-in.shortlyst.com/',
    legacy_brandstore_host: 'https://brands.shortlyst.com/',
    needs_user_consent: false,
    default_consent: true,
    root_domain: 'shortlyst.com',
    app_url: 'https://ncc.shortlyst.com/v2/',
    mira_host: 'https://mira.shortlyst.com/shopalyst-service/v1/mira/actions/',
    shopper_identification_cookie: '_sles_su',
    prd_sdk_host: 'https://products.shortlyst.com/in/'
  }
}
