export default function EventEmitterMixin(toExtend) {
    Object.assign(toExtend.prototype, {
        on(eventName, handler) {
            this.eventListeners = this.eventListeners || {};
            if (Object.keys(this.eventListeners).indexOf(eventName) < 0) {
                throw new Error(`Invalid event name : ${eventName}`);
            }
            if (typeof handler !== 'function') {
                throw new Error('Event handler must be a function');
            }
            this.eventListeners[eventName].push(handler);
        },

        emitEvent(eventName, data) {
            const eventData = {_instance: this, data};
            this.eventListeners[eventName] && this.eventListeners[eventName].forEach((handler) => setTimeout(() => handler(eventData)), 0);
        }
    });
    return toExtend;
}