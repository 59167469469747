import { buildUrl as buildUrlUtil } from 'build-url'

const noOp = () => true

export const UTM_PARAMETER_CONFIG = {
  source: ['utm_source', 'utmsource'],
  medium: ['utm_medium', 'utmmedium'],
  campaign: ['utm_campaign', 'utmcampaign'],
  content: ['utm_content'],
  term: ['utm_term']
}

export const PASSTHROUGH_PARAMETERS = ['sl_audience_segment', 'sl_keyword', 'keyword', 'sl_page_segment', 'advid']

export function addOneTimeListener(el = document.body, eventNames = [], cb = noOp) {
  function getOneTimeListener(eventName) {
    return function oneTimeListener() {
      cb.apply(this, arguments)
      el.removeEventListener(eventName, oneTimeListener)
    }
  }

  eventNames = typeof eventNames === 'string' ? [eventNames] : eventNames
  eventNames.forEach((eventName) => el.addEventListener(eventName, getOneTimeListener(eventName)))
}

export function onAnimationEnd(el = document.body, cb = noOp) {
  return addOneTimeListener(el, 'animationend', cb)
}

/**
 * Helper to parse the query parameters give a URL string
 * @param {string} url - The URL to parse
 * @returns {Object} query - A dictionary of key - value pairs of query parameters of the provided URL. If no query parameters were found, it will be assigned to an empty Object {}
 */
export function parseQueryParameters(url = window.location.href) {
  if (!url) {
    return {}
  }
  const qp = {}
  const queryString = url.split('?')[1]
  if (!queryString) {
    return qp
  }
  return queryString.split('&').reduce(function (m, d) {
    let splits = d.split('=')
    let key = splits[0]
    let value = splits[1]
    if (key && value) {
      m[key] = value
    }
    return m
  }, qp)
}

export function buildSlUtm() {
  const queryParams = parseQueryParameters()
  const source = queryParams.utm_source || queryParams.utmsource || ''
  const medium = queryParams.utm_medium || queryParams.utmmedium || ''
  const campaign = queryParams.utm_campaign || queryParams.utmcampaign || ''
  const content = queryParams.utm_content || ''
  const term = queryParams.utm_term || ''
  var utmParameters = `${source}~${medium}~${campaign}~${content}~${term}`
  return utmParameters
}

export function buildUrl(url, query = {}, path = {}) {
  url = url || window.location.href
  const existingQuery = parseQueryParameters(url)
  // Update URL based on path
  if (Object.keys(path).length && path.pub && path.pl && path.cm) {
    url = buildUrlUtil(url, {
      path: `${path.pub}/${path.pl}/${path.cm}`
    })
    // TODO : After production deployment & due validation
    // remove pub/pl/cm from query
  }
  const consolidatedQuery = { ...existingQuery, ...query }
  const [mainPath = '/'] = url.split('?')
  const queryString = Object.keys(consolidatedQuery)
    .filter((key) => consolidatedQuery[key] !== undefined && JSON.stringify(consolidatedQuery[key]))
    .reduce((m, key, i) => {
      m += `${i !== 0 ? '&' : ''}${key}=${consolidatedQuery[key]}`
      return m
    }, '?')
  return `${mainPath}${queryString}`
}

export function resetPageZoom() {
  let metaEl = document.querySelector('meta[name=viewport]')
  if (!metaEl) {
    metaEl = document.createElement('meta')
    document.head.appendChild(metaEl)
  }
  const originalContent = metaEl.content

  //Reset page zoom
  const oc = metaEl.content || 'initial-scale=1,width=device-width'
  metaEl.setAttribute('content', 'initial-scale=1')
  metaEl.setAttribute('content', 'width=device-width')
  setTimeout(function () {
    metaEl.setAttribute('content', oc)
  }, 100)
}

export const inIframe = () => {
  return window && window.top !== window.self
}

/**
 * Make an AJAX get call
 * @param {string} url
 * @param {object} settings
 * @param {function} successCallback
 * @param {function} errorCallback
 */

export function AJAX_GET(url, settings, successCallback, errorCallback) {
  let isJSON = settings.isJSON || false,
    timesToTry = settings.timesToTry || 0,
    toMiddleware = settings.toMiddleware || false,
    secure = settings.secure || false
  ;(function ajax_call(trialCount) {
    let xhttp = new XMLHttpRequest()
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 204) {
        if (typeof successCallback === 'function') {
          if (isJSON) {
            successCallback(JSON.parse(this.responseText))
          } else successCallback(this.responseText)
        }
      } else if (this.status != 204 && this.status != 200) {
        if (trialCount < timesToTry) {
          setTimeout(ajax_call, 200, ++trialCount)
        } else {
          if (typeof errorCallback === 'function') {
            errorCallback(this.status, this.responseText, this.responseText)
          }
        }
      }
    }
    xhttp.open('GET', url, true)
    xhttp.send()
  })(1)
}

/**
 * MIRA module to record events
 * @param {string} miraHost
 * @param {string} clientId
 * @param {string} publisherId
 * @param {string} placementId
 * @param {string} campaignId
 * @param {boolean} consent
 */
export function MIRA(miraHost, clientId, publisherId, placementId, campaignId, consent) {
  this.miraInitProperties = {}
  this.miraInitProperties['miraHost'] = miraHost
  this.miraInitProperties['clientId'] = clientId
  this.miraInitProperties['publisherId'] = publisherId
  this.miraInitProperties['placementId'] = placementId
  this.miraInitProperties['consent'] = consent
  if (this.campaignId) {
    this.miraInitProperties['campaignId'] = campaignId
  }
}

MIRA.prototype.setProperty = function (property, value) {
  properties[property] = value
}

MIRA.prototype.recordEvent = function (action, eventProperties) {
  let queryParams = {}
  let properties = this.miraInitProperties || {}
  for (let key in properties) {
    queryParams[key] = properties[key]
  }

  for (let key in eventProperties) {
    queryParams[key] = eventProperties[key]
  }
  queryParams['category'] = '0'
  queryParams['referrer'] = window.location.href
  queryParams['page'] = window.location.href

  let buildUrl = (url, parameters) => {
    let qs = ''
    for (let key in parameters) {
      let value = parameters[key]
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value ? value : '') + '&'
    }
    if (qs.length > 0) {
      qs = qs.substring(0, qs.length - 1) //chop off last "&"
      url = url + '?' + qs
    }
    return url
  }

  let url = buildUrl(`${properties.miraHost}${action}`, queryParams)
  AJAX_GET(
    url,
    { secure: window.location.protocol === 'https:' },
    function () {},
    function () {}
  )
}

MIRA.prototype.event = function (event, properties) {
  if (window.MIRA) {
    MIRA.recordEvent(event, properties)
  }
}

/**
 * Helper to read a cookie
 * @param {string} key The cookie key to read
 */
export function readCookie(key) {
  const cookies =
    (document.cookie &&
      document.cookie
        .split(';')
        .map((d) => d.trim())
        .reduce((acc, cookie) => {
          const [key, value] = cookie.split('=')
          if (key && value) {
            acc[key] = value
          }
          return acc
        }, {})) ||
    {}
  if (key) {
    return decodeURIComponent(cookies[key])
  }
  return cookies
}

/**
 * Helper to write a cookie
 * @param {string} key The cookie key to write
 * @param {string} value The cookie value
 * @param {Object} [options={}] Settings to use to create the cookie
 * @param {string} [options.path='/'] The path to store the cookie into
 * @param {string} [options.domain] The domain to store the cookie into
 * @param {number} [options.maxAge=3600] The maxAge to store the cookie for
 */
export function writeCookie(key, value, options = {}) {
  const { path = '/', domain, maxAge = 60 * 60 } = options
  document.cookie = `${key}=${encodeURIComponent(value)};path=${path};domain=${domain};max-age=${maxAge}`
}

export default {
  onAnimationEnd,
  addOneTimeListener,
  parseQueryParameters,
  buildSlUtm,
  buildUrl,
  resetPageZoom,
  inIframe,
  AJAX_GET,
  MIRA,
  readCookie,
  writeCookie
}
