import merge from 'lodash/merge'
import { create } from 'jss'
import preset from 'jss-preset-default'
import fadeInUpBig from 'cssinjs-animations/lib/fade-in-up-big'
import fadeOutDownBig from 'cssinjs-animations/lib/fade-out-down-big'
import fadeOut from 'cssinjs-animations/lib/fade-out'
import fadeIn from 'cssinjs-animations/lib/fade-in'
import fadeInLeftBig from 'cssinjs-animations/lib/fade-in-left-big'
import fadeOutLeftBig from 'cssinjs-animations/lib/fade-out-left-big'
import fadeInRightBig from 'cssinjs-animations/lib/fade-in-right-big'
import fadeOutRightBig from 'cssinjs-animations/lib/fade-out-right-big'
import { onAnimationEnd, addOneTimeListener, resetPageZoom } from './helpers'
import EventEmitterMixin from './event-emitter-mixin'
import userAgent from './userAgent'
import DOMPurify from 'dompurify'
var iosInnerHeightCalculator = require('ios-inner-height')

const jss = create()
jss.setup(preset())

// Account for the strip shown at the bottom of the mobile browsers
var adjustLightboxDimensionsForMobiles = function() {
  if (userAgent.isMobile()) {
    let calculatedDimensions = {
      height: window.innerHeight - 10,
      marginTop: window.innerHeight - iosInnerHeightCalculator()
    }
    return calculatedDimensions
  }
}

function getStyleFromProps(props) {
  return {
    '@keyframes fadeInUpBig': fadeInUpBig,
    '@keyframes fadeOutDownBig': fadeOutDownBig,
    '@keyframes fadeInLeftBig': fadeInLeftBig,
    '@keyframes fadeOutLeftBig': fadeOutLeftBig,
    '@keyframes fadeInRightBig': fadeInRightBig,
    '@keyframes fadeOutRightBig': fadeOutRightBig,
    '@keyframes fadeOut': fadeOut,
    '@keyframes fadeIn': fadeIn,
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      height: userAgent.isIOS ? '100vh' : window.innerHeight,
      width: '100vw',
      background: props.background,
      zIndex: 100000,
      display: 'flex',
      alignItems: !['100vh', '100%'].includes(props.height) ? 'center' : 'normal',
      justifyContent: props.position === 'left' ? 'flex-start' : props.position === 'right' ? 'flex-end' : 'center',
      flexDirection: 'row'
    },
    overlayEntry: {
      animationName: 'fadeIn',
      animationDuration: '1s'
    },
    overlayExit: {
      animationName: 'fadeOut',
      animationDuration: '1s'
    },
    iframe: {
      width: '100%',
      height: '100%',
      background: props.hideLoader
        ? ''
        : `url('https://imgcdn.shortlyst.com/?u=https%3A%2F%2Fstatic.shopalyst.com%2Fassets%2Fcampaigns%2FLoading-Spinner_1603091619293.gif')`,

      backgroundPosition: 'center',
      backgroundSize: '25%',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#ffffff',
      border: 'none',
      position: userAgent.isIOS ? 'absolute' : 'initial'
    },
    container: {
      width: props.width,
      height: userAgent.isIOS ? props.onLoadPageHeight || adjustLightboxDimensionsForMobiles().height : props.height,
      maxWidth: props.maxWidth || '600px',
      minWidth: '300px',
      minHeight: '300px',
      position: 'relative',
      overflowY: userAgent.isIOS ? 'auto' : 'initial',
      '-webkit-overflow-scrolling': 'touch'
    },
    containerEntry: {
      animationName:
        props.position === 'left' ? 'fadeInLeftBig' : props.position === 'right' ? 'fadeInRightBig' : 'fadeInUpBig',
      animationDuration: '1s'
    },
    containerExit: {
      animationName:
        props.position === 'left'
          ? 'fadeOutLeftBig'
          : props.position === 'right'
          ? 'fadeOutRightBig'
          : 'fadeOutDownBig',
      animationDuration: '1s'
    },
    close: {
      height: '56px',
      width: '40px',
      position: 'absolute',
      top: '0',
      [props.closeButton.position]: '0',
      color: props.closeButton.color,
      cursor: 'pointer',
      textAlign: 'center',
      lineHeight: '54px',
      fontSize: props.closeButton && props.closeButton.fontSize ? props.closeButton.fontSize : '28px',
      transform: 'rotateZ(180deg)'
    },
    parentBody: {
      overflow: 'hidden',
      height: window.innerHeight || '100vh',
      width: '100vw'
    },
    parentBodyMobile: {
      overflow: 'hidden',
      height: props.onLoadPageHeight || window.innerHeight || '100%',
      width: '100%',
      position: 'fixed'
    }
  }
}

class Lightbox {
  constructor(props = {}) {
    const defaultProps = {
      position: 'right', // [left, right, center]
      closeButton: {
        type: 'back', //[back, close]
        position: 'left', // [left, right],
        color: 'white', //CSS color
        hide: false
      },
      hideLoader: false,
      background: 'rgba(0,0,0,0.8)',
      closeOnOverlayClick: true,
      onMount: '',
      onUnmount: null,
      onError: null,
      height: userAgent.isIOS ? '100vh' : '100%',
      width: '100%',
      id: 'sl-lightbox'
    }

    var customEventProps = {}
    if (props.position !== 'center') {
      customEventProps = {}
    }
    this.props = merge({}, defaultProps, customEventProps, props)
    this.eventListeners = {
      mount: typeof this.props.onMount === 'function' ? [this.props.onMount] : [],
      unmount: typeof this.props.onUnmount === 'function' ? [this.props.onUnmount] : [],
      error: typeof this.props.onError === 'function' ? [this.props.onError] : []
    }
  }

  open(targetUrl, props) {
    const target = DOMPurify.sanitize(targetUrl)
    merge(this.props, props)
    if (this.isOpen) {
      console.warn('Lightbox is already open. Call close first.')
      return
    }
    const { body } = document
    const jssSheet = jss.createStyleSheet(getStyleFromProps(this.props)).attach()
    const { classes } = jssSheet

    //Reset page zoom
    resetPageZoom()

    const overlay = document.createElement('div')
    overlay.setAttribute('aria-labelledby', 'buy-it-now')
    overlay.setAttribute('aria-modal', 'true')
    overlay.id = 'sl-overlay'
    overlay.className = `${classes.overlay} ${classes.overlayEntry}`
    const sanitisedId = DOMPurify.sanitize(this.props.id)
    if (this.props.closeButton.hide) {
      overlay.innerHTML = `
            <div id="${sanitisedId}" class="${classes.container} ${classes.containerEntry}" data-wt="${this.props
        .widgetType || ''}">
                <iframe src="${target}" class="${classes.iframe}"></iframe>
            </div>
        `
    } else {
      overlay.innerHTML = `
            <div id="${sanitisedId}" class="${classes.container} ${classes.containerEntry}" data-wt="${this.props
        .widgetType || ''}">
                <iframe src="${target}" class="${classes.iframe}"></iframe>
                <div class="${classes.close}">
                    ${this.props.closeButton.type === 'close' ? '&times;' : '&#10132;'}
                </div>
            </div>
        `
    }
    const closeClickHandler = () => this.close()
    const scrollLocker = ev => {
      const scrollTop = body.scrollTop,
        scrollHeight = body.scrollHeight,
        height = window.innerHeight,
        delta = ev.type === 'DOMMouseScroll' ? ev.detail * -40 : ev.wheelDelta,
        up = delta > 0

      const prevent = () => {
        ev.stopPropagation()
        ev.preventDefault()
        ev.returnValue = false
        return false
      }

      if (!up && -delta > scrollHeight - height - scrollTop) {
        // Scrolling down, but this will take us past the bottom.
        body.scrollTop = scrollHeight
      } else if (up && delta > scrollTop) {
        // Scrolling up, but this will take us past the top.
        body.scrollTop = 0
      }
      return prevent()
    }
    if (this.props.closeOnOverlayClick) {
      addOneTimeListener(overlay, 'click', closeClickHandler)
    }

    if (!this.props.closeButton.hide) {
      const closeButton = overlay.querySelector(`.${classes.close}`)
      addOneTimeListener(closeButton, 'click', closeClickHandler)
    }

    const container = overlay.querySelector(`.${classes.container}`)

    if (userAgent.isMobile()) {
      body.className = body.className + ` ${classes.parentBodyMobile}`
      body.setAttribute('data-slscrollx', window.scrollX)
      if (window.scrollY) {
        body.setAttribute('data-slscrolly', window.scrollY)
      } else if (body.scrollTop) {
        body.setAttribute('data-slscrolly', body.scrollTop)
      }
    } else {
      body.className = body.className + ` ${classes.parentBody}`
    }
    body.appendChild(overlay)
    body.addEventListener('DOMMouseScroll', scrollLocker)
    body.addEventListener('mousewheel', scrollLocker)
    body.addEventListener('touchmove', scrollLocker)

    const siteKeyupHandler = event => {
      if (event.key && event.key.toLowerCase() === 'escape') {
        closeClickHandler()
      }
    }
    body.addEventListener('keyup', siteKeyupHandler)

    this.isOpen = true

    this.close = cb => {
      const self = this
      //Start Fading out
      container.className = container.className.replace(classes.containerEntry, classes.containerExit)
      overlay.className = overlay.className.replace(classes.overlayEntry, classes.overlayExit)
      onAnimationEnd(overlay, function() {
        if (!self.close) {
          // Already closed, handler executing again: ignore
          return
        }
        jssSheet.detach()
        body.removeChild(overlay)
        if (userAgent.isMobile()) {
          body.className = body.className.replace(classes.parentBodyMobile, '').trim()
          body.removeAttribute('data-slscrollx')
          if (window.scrollY) {
            window.scrollTo(body.getAttribute('data-slscrollx'), body.getAttribute('data-slscrolly'))
          } else if (body.scrollTop) {
            body.scrollTo(body.getAttribute('data-slscrollx'), body.getAttribute('data-slscrolly'))
          }
          body.removeAttribute('data-slscrolly')
        } else {
          body.className = body.className.replace(classes.parentBody, '').trim()
        }
        body.removeEventListener('DOMMouseScroll', scrollLocker)
        body.removeEventListener('mousewheel', scrollLocker)
        body.removeEventListener('touchmove', scrollLocker)
        body.removeEventListener('keyup', siteKeyupHandler)
        self.isOpen = false
        self.close = null
        self.emitEvent('unmount', { target })
        typeof cb === 'function' && cb()
      })
    }
    this.emitEvent('mount', { target })
  }
}

export default EventEmitterMixin(Lightbox)
