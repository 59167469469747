import merge from 'lodash/merge'
import { create } from 'jss'
import preset from 'jss-preset-default'
import EventEmitterMixin from './event-emitter-mixin'
import userAgent from './userAgent'
import DOMPurify from 'dompurify'

const jss = create()
jss.setup(preset())

function getStylesFromProps(props) {
  return {
    root: {
      width: props.width,
      height: props.height,
      display: 'block',
      margin: 'auto',
      overflow: userAgent.isIOS ? 'auto' : 'initial',
      '-webkit-overflow-scrolling': 'touch'
    },
    iframe: {
      height: '100%',
      width: '100%',
      //background: `url('https://imgcdn.shortlyst.com/?u=http%3A%2F%2Fstatic.shopalyst.com%2Fassets%2Fcampaigns%2Fshopalyst_loader_1521450633203.gif')`,
      background: 'transparent',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      border: 'none'
    }
  }
}

const defaultProps = {
  container: 'body',
  height: '100%',
  width: '100%',
  onMount: null,
  onUnmount: null,
  onError: null
}

class Embed {
  constructor(props = {}) {
    this._id = props.id || Math.floor(Math.random() * 100000)
    this.props = merge({}, defaultProps, props)
    this.eventListeners = {
      mount: typeof this.props.onMount === 'function' ? [this.props.onMount] : [],
      unmount: typeof this.props.onUnmount === 'function' ? [this.props.onUnmount] : [],
      error: typeof this.props.onError === 'function' ? [this.props.onError] : [],
      merchant_clicked: typeof this.props.onMerchantClicked === 'function' ? [this.props.onMerchantClicked] : []
    }
  }

  open(targetUrl, props = {}) {
    const target = DOMPurify.sanitize(targetUrl)
    const currentProps = merge({}, this.props, props)
    const jssSheet = jss.createStyleSheet(getStylesFromProps(currentProps)).attach()
    const { classes } = jssSheet

    const root = document.createElement('div')
    root.className = `${classes.root} sl-root sl-embed`

    root.innerHTML = `<iframe class="${classes.iframe}" src="${target}" ></iframe>`

    //TEMP PLUG TO HANDLE DOM SELECTORS WHICH ARE NUMERIC IDs
    let selector = currentProps.container
    const regExp = /^#[0-9]/
    selector = selector.replace(regExp, function(match) {
      return `#\\3${match.split('#')[1]} `
    })
    //TEMP PLUG END

    const container = document.querySelector(selector) || document.body
    //TEMP PLUG T REMOVE OLD EMBED DO NOT USE FOR NEW INTEGRATION USE .close instead
    let staleEmbed = container.querySelector('div.sl-root')
    if (staleEmbed) {
      container.removeChild(staleEmbed)
    }
    //TEMP PLUG END
    container.appendChild(root)

    this.close = function(cb) {
      container.removeChild(root)
      this.emitEvent('unmount', { target, wid: this._id })
      typeof cb === 'function' && cb()
      window.removeEventListener('message', handlePostMessage)
    }

    const handlePostMessage = event => {
      const { data } = event
      if (!data.slMessage || this._id !== data.wid) {
        return
      }
      // TODO : Check & remove since event emitted should be named
      this.emitEvent(data.type, { ...data, target, wid: this._id })
      data.event && this.emitEvent(data.event.toLowerCase(), { ...data, target, wid: this._id })
    }

    window.addEventListener('message', handlePostMessage)

    this.emitEvent('mount', { target, wid: this._id })
  }
}

export default EventEmitterMixin(Embed)
